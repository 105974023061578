
$brand: #3B5D26;
$brand-light: #4F7C33;
$grey: #C4C4C4;
$secondary: #A33031;
$shadow: 4px 4px 4px rgba(0,0,0,0.3);

/*
==============================
Fonts
==============================
*/

$ff:   'Poppins', sans-serif;