@import "system/variables";
@import "system/mixin";
// @import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap');

input::-webkit-input-placeholder {
  color: $grey !important;
}

input::-moz-placeholder {
  color: $grey !important;
}

input:-ms-input-placeholder {
  color: $grey !important;
}

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  font-family: Poppins, sans-serif;
  line-height: 1.3;
  box-sizing: border-box;
  margin: 0;
  overflow-x: hidden;

  @include respond-to(xs) {
    font-size: 14px;
  }
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

p {
  margin-bottom: 0;
}

img {
  width: 100%;
}

a {
  color: $secondary;
  transition: all 0.4s ease;
  text-decoration: none;

  &:hover {
    color: $brand;
    text-decoration: none;
  }
}

.flex {
  display: flex;

  &-wrap {
    flex-wrap: wrap;
  }
}

.jc-between {
  justify-content: space-between;
}

.site-width {
  width: 1200px;
  max-width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  @include vw($width: 1200,
    $padding: 0 15);

  @include respond-to(lg) {
    // width: 890px;
  }

  @include respond-to(xs) {
    padding: 0px 40px;
  }
}

.color-brand {
  color: $brand;
}

.btn {
  @include vw($font-size: 27,
    $border-radius: 60,
    $padding: 25 65);
  color: #fff;
  background: $brand;
  display: inline-block;
  position: relative;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3),
    0 0 40px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.5),
    0 1px 4px rgba(0, 0, 0, 0.3),
    0 0 40px rgba(0, 0, 0, 0.1) inset;
  transition: all 0.4s ease;

  &--secondary {
    background: $secondary;
  }

  &:after {
    content: "";
    content: "";
    position: absolute;
    z-index: -2;


  }

  &:hover {
    color: #fff;
    opacity: 0.9;
  }

  @include respond-to(xs) {
    font-size: 20px;
    border-radius: 54px;
    padding: 20px 40px;
  }
}

.header { 
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  @include vw($padding: 30 0);
  background: rgba(0, 0, 0, 0.5);
  background: rgb(139, 139, 139);
  position: fixed;

  .site-width {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include respond-to(xs) {
      flex-wrap: wrap;
    }
  }

  &__discount {
    @include vw($font-size: 28,
      $margin-top: 0);
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    font-weight: 700;
    display: none;

    @include respond-to(xs) {
      flex-basis: 100%;
      order: 0;
      margin-bottom: 15px;
      font-size: 16px;
      text-align: left;
    }

    p {
      @include vw($margin-top: 0,
        $margin: 0 10 0);
    }

    .time {
      @include vw($min-width: 100);
    }

    span {
      font-weight: 700;
      @include vw($font-size: 36);
      color: #fff;
    }
  }

  &.special-offer {

    .header__discount {
      display: flex;
    }
  }

  &__logo {
    @include vw($flex-basis: 200);

    @include respond-to(xs) {
      flex-basis: 116px;
      order: 1;
    }

    img {
      width: 100%;
    }
  }

  &__btns {
    // flex-basis: 150px;
    // @include vw($flex-basis: 150);

    order: 3;
  }

  &__btn {

    @include vw($font-size: 20, $padding: 20 20,
      $border-radius: 50);
    text-transform: uppercase;
    color: #000;
    background: #fff;
    transition: all 0.4s ease;
    ;

    @include respond-to(xs) {
      border-radius: 35px;
      top: 35px;
      right: 10px;
    }

    &.is-dark {
      background: #000;
      color: #fff;
    }
  }

  &.special-offer {
    @include respond-to(xs) {
      padding-top: 5px;
      padding-bottom: 0;
    }

    .site-width {
      @include respond-to(xs) {
        justify-content: flex-end;
        padding-top: 15px;
      }
    }

    .header__logo {
      @include respond-to(xs) {
        display: none;
      }
    }

    .header__discount {
      @include respond-to(xs) {
        margin: 0;
      }
    }

    .time {
      @include respond-to(xs) {
        font-size: 20px;
        text-transform: uppercase;
        color: #fff;
        background: #a33031;
        transition: all 0.4s ease;
        ;
        padding: 5px;
        /* display: flex; */
        min-width: 100px;
        text-align: center;
        margin-right: 0;

        border-radius: 35px;
        top: 35px;
        right: 10px;

      }
    }

    .header__btn {
      background: #a33031;
      color: #fff;

      &:hover {
        opacity: 0.8;
      }

      @include respond-to(xs) {
        padding: 0;
        border: none;
        background: none;
        font-size: 12px;
        min-width: 100px;
        text-align: center;
        display: inline-block;
      }
    }
  }
}

/*  HERO  */
.hero {
  background: url(/images/bg-hero-1-1.png) top center no-repeat;
  height: 100vh;
  background-size: cover !important;
  z-index: 9;
  position: relative;
  // clip-path: ellipse(120% 50% at 50% 50%);
  @include vw($margin-top: 140,
    $padding-bottom: 0,
    $min-height: 1130);

  @include respond-to(xs) {
    padding-top: 120px;
    
    margin-top: 88px; 
    background: url('/images/bg-hero-m.png') top center no-repeat;
    background-size: cover;
    height: auto;
    min-height: 100vh;
  }

  @include respond-to(420) {
    padding-top: 13vh;
    padding-top: 2vh;
  }

  @include respond-to(330) {
    height: 800px;
  }

  &__bg {
    background: url(/images/bg-hero-1-1.png) bottom center no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;

    @include respond-to(xs) {
      background: url('/images/bg-hero-m.png') top center no-repeat;
      background-size: cover;
    }
  }

  &.is-active {

    // background: url('/images/bg-hero.png') bottom center no-repeat;
    // background-size: cover;
    .hero__bg {
      opacity: 1;
      visibility: visible;
      background: rgba(0,0,0,0.7);
    }
  }

  .site-width {
    display: flex;

    @include respond-to(xs) {
      flex-wrap: wrap;
    }
  }

  &__left {
    @include vw($padding-bottom: 90,
      $max-width: 525,
      $flex-basis: 525, $padding-top: 50);
    flex-shrink: 0;
    z-index: 99;

    @include respond-to(xs) {
      flex-basis: 100%;
      padding-top: 0px;
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-end;
    @include vw($padding-left: 90,
      $padding-bottom: 90);
    flex-grow: 1;

    @include respond-to(xs) {
      flex-basis: 100%;
      position: absolute;
      bottom: 100px;
      right: 0;
      padding-left: 63px;
      position: absolute;
      bottom: 180px;
      right: 0;
      padding-left: 63px;
      position: absolute;
      bottom: 100px;
      right: 0;
    }

    @include respond-to(420) {
      // bottom: 17vh;
      bottom: 11vh;
    }

    // @include respond-to(380){
    //   bottom: 120px;
    // }
  }

  &__content {}

  &__title {
    color: #fff;
    font-weight: 700;
    @include vw($font-size: 50, $letter-spacing: 1px);
    line-height: 1.1;
    @include vw($margin-bottom: 0,
    $margin-top: 160);
    position: relative;
&:after{
content: '';
@include vw($width: 200,
$height: 190);
position: absolute;
display: block;
@include vw($top: -120,
$left: 0);
background: url(/images/quote.png) center center no-repeat;
background-size: contain;
}
    span {
      font-weight: 700;
      font-style: italic;
      @include vw($font-size: 100);
      display: inline-block;
      position: relative;
      &:after{
        content: '';
        position: absolute; 
        width: 100%;
        left: 0;
        @include vw($height: 10,
        $bottom: 14);
        background: $brand;;
        z-index: -1;;

      }
    }

    @include respond-to(xs) {
      font-size: 31px;
      margin-bottom: 30px;

      br {
        display: none;
      }

      span {
        display: block;
      }

    }

  }

  &__text {
    color: #fff;
    font-weight: 700;
    @include vw($font-size: 21, $margin-bottom: 40);

    @include respond-to(xs) {
      font-size: 16px;
      margin-bottom: 20px;
    }

    p {
      font-weight: 300;
      // font-size: 16px;
      line-height: 1.4;
      @include vw($font-size: 16,
        $margin-top: 5,
        $padding-left: 15);
    }
  }

  &__slide {
    background: rgba(255, 255, 255, 0.3);
    @include vw($height: 495,
      $width: 120, $margin-left: 70);
    border-radius: 60px;
    position: relative;

    @include respond-to(xs) {
      height: 262px;
      width: 97px;
      margin-left: 0px;
      margin-left: 0;
      position: absolute;
      bottom: 80px;
      width: 270px;
      height: 67px;
      right: auto;
      left: 50%;
      transform: translate(-50%, 0);
    }

    @include respond-to(420) {
      bottom: 11vh;
    }

    &:after {
      content: '';
      position: absolute;
      border-right: 1px dashed rgba(255, 255, 255, 0.4);
      width: 1px;
      height: 90%;
      top: 50%;
      left: 50%;
      z-index: 0;
      transform: translateY(-50%);

    }

    &-circle {
      border-radius: 50%;
      @include vw($height: 120,
        $width: 120);
      color: #fff;
      z-index: 2;
      // display: flex;
      // align-content: center;
      // justify-content: center;
      // align-items: center;
      // position: relative;
      background: $secondary;
      font-weight: 600;
      @include vw($font-size: 16);
      text-align: center;
      cursor: pointer;
      border: 1px solid #541112;
      position: absolute;
      top: 0px;
      left: 0;
      transition: all 0.6s ease-out;
      transform: translateY(0%);

      &.is-active {
        top: 100%;
        background: #CBE5D4;
        border: 1px solid #114623;
        color: $brand;
        transform: translateY(-100%);

        @include respond-to(small) {
          transform: translate(100%, -100%);
        }

        .hero__slide-circle-bg {
          background: url(/images/smile-2.png) center center;
          background-size: 98%;
        }

      }

      @include respond-to(xs) {
        font-size: 16px;
        width: 97px;
        height: 97px;
        font-weight: 500;
        padding: 7px;
        font-size: 16px;
        width: 137px;
        height: 70px;
        font-weight: 500;
        padding: 7px;
        border-radius: 60px;
      }

      &-bg {
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: url(/images/smile-bad.png) center center;
        background-size: 98%;
        transition: all 0.6s ease-out;
      }

      span {
        position: relative;
        z-index: 9;
        ;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
      }
    }
  }
.divider{
  @include respond-to(xs){
    transform: translate(-50%,40%);
    bottom: 0;
  }
}
}

.ingr {
  &__btn {
    @include vw($width: 280,
      $height: 280,
      $margin-right: 50,
      $margin-bottom: 30);
    align-self: flex-end;
    background: url('/images/ingr.png') center center no-repeat;
    background-size: contain;
    cursor: pointer;

    @include respond-to(xs) {
      width: 150px;
      height: 150px;
      margin-right: 35px;
    }

    h2 {
      color: #fff;
      @include vw($font-size: 40,
        $margin: 20 0,
        $padding: 10 0);
      line-height: 1;
      text-align: center;
      display: none;
    }

    span {
      display: none;
    }
  }

  &__list {
    display: none;

    @include respond-to(xs) {
      padding-right: 25px;
      z-index: 99;
      position: relative;
      // background: #000;
    }

    &-inner {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    &-item {
      text-align: center;
      @include vw($border: 2 solid #fff,
        $padding: 10 10,
        $font-size: 22,
        $border-radius: 6,
        $margin-bottom: 10) color: #fff;
      width: 100%;
      @include vw($max-width: 390);
      width: 100%;
      align-self: flex-end;

      @include respond-to(xs) {
        background: #000;
      }

      &:hover {
        color: #000;
        background: #fff;
      }
    }

    &-close {
      margin: auto;
      color: #fff;
      line-height: 0.6;
      @include vw($margin-bottom: 30,
        $font-size: 30);
      @include vw($max-width: 390);
      width: 100%;

      margin-left: auto;
      margin-right: 0;
      text-align: center;
    }
  }

  &__info {
    color: #fff;
    // @include vw($padding-bottom: 70);
    margin-bottom: auto;
    margin-top: auto;
    align-self: flex-start;
    display: none;
    position: relative;
    @include vw($font-size: 19);

    &.is-visible {
      display: block;
    }

    @include respond-to(small) {
      @include vw($font-size: 16);
    }

    &-close {
      float: right;
      color: #fff;
      @include vw($font-size: 30);
      line-height: 0.6;
      cursor: pointer;
    }

    &-next {
      position: absolute;
      top: 50%;
      right: 0;
      @include vw($right: -90,
        $font-size: 90);
      display: block;
      transform: translate(0%, -30%);

      &:hover {
        opacity: 0.8;
        cursor: pointer;
        ;
      }

    }

    h2 {
      @include vw($margin: 0 auto 30);
    }
  }
}

.bg-text {
  background: url(/images/bg-text1.jpg) top center repeat-y;
  background-size: 100%;

  @include respond-to(xs) {
    background-size: contain;
  }
}

.bg-text-2 {
  background: url(/images/bg-text-2.jpg) no-repeat;
  background-size: 100%;
  @include vw($background-position: center 140);

  @include respond-to(xs) {
    background-size: cover;
  }
}

.wave {
  @include vw($width: 373);
  position: absolute;
  // top: 50%;
  left: 0%;

  @include respond-to(xs) {
    width: 185px;
  }

  img {
    width: 100%;
  }
}

.divider {
  @include vw($width: 8,
    $height: 310);
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #000;
  z-index: 0;
  border-radius: 5px;

  @include respond-to(xs) {
    width: 4px;
    height: 140px;
  }
}

.js-pin {
  position: relative;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;

  // overflow: hidden;
  @include respond-to(xs) {
    height: auto;
    height: 100vh;
  }
}

.reduce {
  position: absolute;
  width: 100%;
  // height: auto;
  z-index: 2;
  @include vw($margin-top: -45);

  @include respond-to(xs) {
    margin-top: -5px;
    position: relative;
  }

  .divider {
    bottom: -25%;

    @include respond-to(xs) {
      bottom: -64%;
      height: 210px;
    }
  }

  &__bg {

    background: url(/images/reduce-bg.png) no-repeat;
    // background-size: cover;
    background-size: 118%;
    background-position: -520px -906px;
    /* background-size: 100%; */
    background-position: 150% 104%;
  }

  &__wave {
    top: 32%;

    @include respond-to(xs) {
      top: 21%;
    }
  }

  &__img {
    position: relative;
    @include vw($width: 920, $margin-left: 35);
    max-width: 100%;

    &-wrap {
      opacity: 1;

    }

    @include respond-to(xs) {
      width: 81%;
      width: 100%;
      margin: 0 auto;
    }

    &--1 {
      // &:nth-child(2){
      position: relative;
      top: 0;
      left: 0;
      z-index: 2;

      @include respond-to(xs) {
        //  opacity: 0;
      }

      // }
    }

    &--2 {
      // &:nth-child(2){
      position: absolute;
      top: 0;
      left: 0;
      // }
    }
  }

  &__action {
    position: absolute;
    position: absolute;
    top: 0;
    left: 33%;
    z-index: 3;
    @include vw($top: 530);
    // top: 350px;
    // left: 200px;
    opacity: 1;
    transform: translateY(0%);

    @include respond-to(xs) {
      top: 49%;
      left: 31%;

    }

    // background: red;
    &-btn {
      color: #fff;
      @include vw($width: 120,
        $height: 120,
        $font-size: 24);
      font-weight: bold;
      background: url(/images/btn-dashed.png) center center no-repeat;
      background-size: cover;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      cursor: pointer;

      // opacity: 0;
      // transform: rotate(90deg);
      @include respond-to(xs) {
        font-size: 16px;
        width: 60px;
        height: 60px;
      }

      &-text {
        opacity: 0;
        position: absolute;
        color: #fff;
        @include vw($width: 120,
          $height: 120,
          $font-size: 24);
        top: 0;
        z-index: 99;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        // top: 50%;
        // left:0;
        @include respond-to(xs) {
          // opacity: 1
          font-weight: 400;
          font-size: 14px;
          text-align: center;
          display: block;
          padding: 5px;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;

          br {
            display: none;
          }
        }
      }
    }

    &-line {
      position: absolute;
      @include vw($height: 4,
        $right: -430,
        $width: 430);
      // background: $brand; 
      top: 40%;

      transform: translateX(0%);

      @include respond-to(xs) {
        height: 4px;
        width: 157px;
        width: 167px;
        transform: translateX(-100%);
        right: -180%;

      }

      span {
        background: $brand;
        display: block;
        width: 0;
        height: 100%;
        float: right;

        @include respond-to(xs) {
          float: none;
        }
      }
    }

    &-title {
      position: absolute;

      color: green;
      font-weight: bold;
      line-height: 1.2;
      @include vw($left: 560,
        $font-size: 50,
        $margin-top: 18);
      // transform: translateY(100%);
      // opacity: 0;

      @include respond-to(xs) {
        display: none;
        
      }

    }
  }

  &__title {
    position: absolute;
    top: 0;
    left: 43%;
    font-weight: bold;
    line-height: 1;
    color: $grey;
    @include vw($font-size: 119, $margin-top: 400, $letter-spacing: 3);

    @include respond-to(xs) {
      font-size: 45px;
      position: relative;
      left: auto;
      top: auto;
      margin: 0px auto 0;
      width: 100%;
      max-width: 280px;
      
    }

    span {
      display: block;
      @include vw($font-size: 148, $letter-spacing: 1);
      line-height: 0.7;

      @include respond-to(xs) {
        font-size: 45px;
        line-height: 1;
      }

    }

    &--sm {
      display: none;

      @include respond-to(xs) {
        display: block;
        color: $brand;
        font-weight: bold;
        line-height: 1.2;
        width: 100%;
        max-width: 280px;
        margin: -10px auto 0;

      }
    }
  }
}

.benefit {
  // @include vw($padding-top: 290);
  @include vw($padding-top: 100);
  position: relative;
  z-index: 2;

  @include respond-to(xs) {
    padding-top: 170px;
    padding-top: 0;
  }

  .js-benefit-pin {
    position: relative;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;

    @include respond-to(xs) {
      // height: auto;;
    }
  }

  &__title {
    @include vw($font-size: 80);
    font-weight: 700;
    text-align: center;
    line-height: 1.2;

    @include vw($padding-bottom: 100);
    position: relative;

    @include respond-to(xs) {
      font-size: 50px;
      padding-bottom: 30px;
      padding-top: 100px;
      padding-top: 0;
    }

    .divider {
      top: 0;
      transform: translate(0%, -110%);
      display: none;
      @include respond-to(xs){
        display: none;
      }
    }

    span {
      font-weight: 900;
      color: $brand;
    }

  }

  &__spacer {
    @include vw($height: 53);

    @include respond-to(xs) {
      // display: none;
      height: 40px;
    }
  }

  &__inner {
    position: relative;

    @include respond-to(xs) {
      max-width: 295px;
      width: 100%;
      margin: 0 auto;
    }
  }

  &__imgs {
    @include vw($margin:0 auto 0);
    position: relative;
    width: 65%;
    padding-bottom: 53%;

    @include respond-to(xs) {
      // margin: 70px auto 0px;
      margin: 0px auto 0px;
      width: 100%;
      padding-bottom: 80%;

    }
  }

  &__img {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    display: block;

    &--1 {
      opacity: 1;
      z-index: 2;

      @include respond-to(xs) {
        // opacity: 0
      }
    }

    &--2 {
      z-index: 1;
      opacity: 0;

      @include respond-to(xs) {
        // opacity: 1;
      }
    }
  }

  &__points {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // opacity: 0;
    z-index: 3;

    @include respond-to(xs) {
      // opacity: 1;
    }

  }


  .point {
    border: 6px solid rgba(98, 30, 31, 0.5);
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 42px;
    // height: 42px;
    opacity: 0;
    @include vw($width: 42,
      $height: 42);

    @include respond-to(xs) {
      width: 15px;
      height: 15px;
      border: 3px solid rgba(98, 30, 31, 0.5);
      // opacity: 1;;
    }

    // @include respond-to(lg){
    //   border-width: 4px;
    //   width: 22px;
    //   height: 22px;
    // }
    span {
      display: block;
      // width: 30px;
      // height: 30px;
      background: #FD6163;
      border-radius: 50%;
      flex-grow: 0;
      // border: 1px dashed #fff;
      @include vw($width: 30,
        $height: 30);

      @include respond-to(xs) {
        width: 11px;
        height: 11px;
        border: 0;
      }
    }
  }

  &__point {
    @include vw($width: 42,
      $height: 42);
    position: absolute;

    @include respond-to(xs) {
      width: 15px;
      height: 15px;
    }

    .line {
      width: 2px;
      background: none;
      position: absolute;

      span {
        background: #FF5153;
        height: 0%;
        display: block;

        @include respond-to(small) {
          // height: 100%;
        }
      }
    }

    &--1 {
      top: 80%;
      left: 20%;

      @include respond-to(xs) {
        top: 80%;
        left: 0%;
      }

      .line {
        z-index: 2;
        @include vw($height: 234,
          $top: 10);
        transform: rotate(18deg);
        left: -41%;

        @include respond-to(xs) {
          height: 140px;
          top: 9px;
          transform: rotate(-1deg);
          left: 52%;
        }
      }
    }

    &--2 {
      top: 27%;
      left: 48%;

      .line {
        z-index: 2;
        transform: rotate(-47deg);
        left: 525%;
        @include vw($height: 522,
          $top: -55);

        @include respond-to(xs) {
          height: 201px;
          top: -16px;
          transform: rotate(-44deg);
          left: 525%;
        }
      }
    }

    &--3 {
      top: 48%;
      left: 64%;

      @include respond-to(xs) {
        top: 52%;
        left: 72%;
      }

      .line {
        z-index: 2;
        transform: rotate(-42deg);
        left: 278%;
        @include vw($height: 324,
          $top: -27);

        @include respond-to(xs) {
          height: 111px;
          top: -2px;
          transform: rotate(-38deg);
          left: 278%;
        }
      }
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;

    // visibility: hidden;
    // opacity: 0;
    &-title {
      @include vw($font-size: 40,
        $padding-bottom: 34,
        $margin-bottom: 34);
      font-weight: 700;
      position: relative;

      @include respond-to(xs) {
        font-size: 16px;
        ;
        padding-bottom: 10px;
        margin-bottom: 10px;
      }

      &:after {
        content: '';
        background: $secondary;
        height: 2px;
        @include vw($width: 255);
        position: absolute;
        left: 0;
        bottom: 0;

        @include respond-to(xs) {
          width: 100%;
        }
      }
    }

    &-text {
      @include vw($font-size: 26,
        $margin-bottom: 50);
      font-weight: 500;
      line-height: 1.34;

      @include respond-to(xs) {
        font-size: 12px;
        line-height: 1.5;
        margin-bottom: 20px;
      }
    }

    &-link {
      @include vw($font-size: 18);
      font-weight: 700;
      color: $secondary;
      display: none;

      @include respond-to(xs) {
        display: inline-block;
        max-width: 130px;
      }
    }

    &-left {
      @include vw($padding-left: 150,
        $padding-top: 130);
      opacity: 0;
      height: 0;
      overflow: hidden;
      transform: translateY(20%);

      @include respond-to(xs) {
        padding-left: 0px;
        padding-top: 100px;
        // transform: translate(0);
        // overflow: visible;
        // opacity: 1;
        // height: auto;
      }

      .benefit__content-title {
        @include vw($padding-right: 30);

        @include respond-to(xs) {
          padding-right: 0;
        }

      }
    }

    &-right {
      text-align: right;

      opacity: 0;
      height: 0;
      overflow: hidden;
      transform: translateY(20%);

      @include respond-to(xs) {
        // transform: translate(0);
        // overflow: visible;
        // opacity: 1;
        // height: auto;
      }

      .benefit__content-title {
        text-align: right;

        &:after {
          left: auto;
          right: 0;
        }
      }

      .benefit__content-text {
        @include vw($padding-left: 120);

        @include respond-to(xs) {
          padding-left: 53px;

        }
      }
    }
  }
}

.review {
  background: url(/images/review-bg.png) right top no-repeat;
  text-align: center;
  // background-size: cover;
  background-size: contain;
  z-index: 1;
  @include vw($margin-top: -170,
    $padding-top: 790,
    $padding-bottom: 1000);
  // @include vw($margin-top: -600,
  //   $padding-top: 790,
  //   $padding-bottom: 1000);
  position: relative;

  @include respond-to(xs) {
    background: url(/images/review-bg-m.png) top center no-repeat;
    margin-top: 140px;
    padding-top: 128px;
    padding-bottom: 110px;
    background-size: cover;
  }


  &__wrap {
    width: 70%;
    margin: auto;
    text-align: center;

  }

  .bx-controls {
    text-align: center;
    @include vw($margin-top:20);

    .bx-pager-item {
      display: inline-block;

      .bx-pager-link {
        @include vw($width:70, $height:10, $margin-left:7, $margin-right:7,
          $border-radius: 9,
          $border: 2 solid #000);
        border-radius: 0;
        text-indent: -99999px;
        display: block;
        outline: 0;

        &.active {
          background: #000;
        }
      }
    }
  }

  .site-width {
    position: relative;

    @include respond-to(xs) {}
  }

  &__wave {
    left: auto;
    right: 0;
    top: 65%;

    @include respond-to(xs) {
      left: auto;
      right: 0;
      top: 90%;
      width: 145px;
    }
  }

  &__img {
    @include vw($width: 140,
      $height: 140);
    margin: 0 auto;

    @include respond-to(xs) {
      width: 75px;
      height: 75px;
      // margin-top: 77px;
    }
  }

  &__title {
    @include vw($font-size: 67,
      $margin: 85 auto 25);
    font-weight: 900;
    font-style: italic;

    @include respond-to(xs) {
      font-size: 30px;
      margin: 50px auto 0;
    }
  }

  &__text {
    @include vw($font-size: 30,
      $margin: 0 auto 80);
    font-weight: 500;
    font-style: italic;
    width: 84%;

    @include respond-to(xs) {
      font-size: 14px;
      margin-bottom: 40px;
    }

  }

  &__author {
    @include vw($font-size: 23,
      $margin: 0 auto 60);
    font-weight: 400;

    @include respond-to(xs) {
      font-size: 15px;
      margin: 0px auto 40px;
    }

  }

  .btn {
    @include vw($margin-bottom: 50);
  }

  .divider--top {
    @include vw($top: 480);

    @include respond-to(xs) {
      top: 10px;
    }
  }

  .divider--bottom {
    @include vw($top: auto,
      $bottom: 0,
      $height: 1060); 
      background: $brand;
    transform: translate(0, 120%);
    @include respond-to(xs){
      height: 152px
    }
  }
}

.feeling {
  @include vw($padding-top: 180,
  $margin-top: -130);
  // @include vw($margin-top: -570);
  // background: rgba(236, 236, 236, 1) ;
  position: relative;
  z-index: 3;
  overflow: hidden;

  @include respond-to(xs) {
    padding-top: 46px;
    margin-top: -30px;
    margin-bottom: 80px;
    background: none;
  }

  .site-width {
    @include respond-to(xs) {
      width: 100%;
      padding: 0;
    }
  }

  &__title {
    @include vw($font-size: 80);
    font-weight: 700;
    text-align: center;
    position: relative;
    z-index: 3;

    @include respond-to(xs) {

      font-size: 50px;
      margin-bottom: 20px;
    }

    span {
      font-weight: 900;
      color: $brand;
    }
  }

  &__subtitle {
    @include vw($font-size: 37);
    color: #A3A2A2;
    text-align: center;
    font-weight: 400;
    position: relative;
    z-index: 3;

    @include respond-to(xs) {
      font-size: 21px;
      color: $secondary;
      font-weight: 700;
      margin-bottom: 20px;
    }
  }
&__main{
  .feeling__img{
    @include respond-to(xs){
      display: none;
    }
  }
}
  &__main-desktop {
    @include respond-to(xs) {
      display: none;
    }
  }

  &__nav {
    @include vw($margin: 270 0 0);
    position: relative;
    z-index: 2;
    @include vw($width: 600,
      $padding: 0 15,
      $height: 850);
    max-width: 100%;

    @include respond-to(xs) {
      margin-top: 10px;
      width: 100%;
      padding: 0px 40px;
    }
  }

  &__link {
    position: absolute;
    width: 100%;
    visibility: visible;

    // transition: transform 0.9s ease;
    &:nth-child(1) {
      transform: translateY(0);
    }

    &:nth-child(2) {
      transform: translateY(100%);
    }

    &:nth-child(3) {
      transform: translateY(200%);
    }

    &:nth-child(4) {
      transform: translateY(300%);
    }

    &:nth-child(5) {
      transform: translateY(400%);
    }

    &.is-active {
      transform: translate(0);

    }

    &-title {
      @include vw($font-size: 50,
        $padding: 22 0,
        $margin: 0);
      font-weight: 700;
      color: #000;
      display: block;
      line-height: 1.4;
      position: relative;
      transition: all 0.6s ease;
      cursor: pointer;

      &:after {
        content: '';
        width: 100%;
        // @include vw($width: 408);
        height: 1px;
        bottom: 0;
        position: absolute;
        display: block;
        background: #000;

        @include respond-to(xs) {
          width: 100%;
        }
      }

      &:before {
        content: '';
        width: 150%;
        height: 90%;
        position: absolute;
        top: 5%;
        right: 0;
        background: rgb(199, 53, 58);
        display: block;
        z-index: -1;
        ;
        opacity: 0;
        transition: all 0.6s ease;

        @include respond-to(small) {
          width: 100%;
        }
      }

      &:hover {
        color: #fff;

        &:before {
          opacity: 1;
        }
      }

    }

    @include respond-to(xs) {
      font-size: 30px;
      padding: 22px 0px;
    }

    &-content {
      display: none;
      opacity: 0;
      visibility: hidden;
    }
  }

  &__img {

    opacity: 0.4;
    position: absolute;
    right: 0;
    ;
    overflow: hidden;
    // background: url('/images/feeling-1-1.png') center center no-repeat;
    background-size: 100%;
    @include vw($height: 1320,
      $width: 1090,
      $margin-top: -450);

    img {
      min-width: 100%;
      min-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: auto;
      display: block;
      transform: translate(-50%, -50%);
      opacity: 0;
      visibility: hidden;

      &:first-child {
        opacity: 1;
        visibility: visible;
      }
    }

    @include respond-to(xs) {
      height: auto;
      width: 100%;
      margin-top: 0;
      clip-path: none;
      shape-outside: none;
      // padding-bottom: 110%;
      height: 400px;
      float: none;
      position: static;
      background: url('/images/feeling-1-m.jpg') center center no-repeat;
    }
  }

  .feeling__img {
    background-size: 100% !important;
  }

  &__tab {
    // display: flex;
    position: relative;
    display: none;
    opacity: 0;
    visibility: hidden;
    ;

    &.is-visible {
      display: block;
      opacity: 1;
      visibility: visible;
    }

    &-title {
      @include vw($margin-top: 270);
      @include vw($font-size: 50,
        $padding: 22 0,
      );
      font-weight: 700;
      color: $brand;
      display: block;
      line-height: 1.4;
      position: relative;

      @include respond-to(xs) {
        margin-top: 30px;
      }

      &:after {
        content: '';
        @include vw($width: 408);
        height: 1px;
        bottom: 0;
        position: absolute;
        display: block;
        background: $brand;
      }

      @include respond-to(xs) {
        font-size: 30px;
      }
    }

    &-left {
      // width: 40%;
      @include vw($width: 1200,
        $margin: 0 auto,
        $padding: 0 15);
      max-width: 100%;

    }

    &-content {
      // height: 640px;
      overflow: auto;
      // padding-right: 10px;
      @include vw($height: 600,
        $padding-right: 30);

      p {
        @include vw($font-size: 30,
          $margin: 0 0 30);
        font-weight: 400;
      }
    }

    .feeling__img {
      opacity: 1;
      background-size: 100% !important;

      &--joint {}

      &--back {
        background: url(/images/feeling-4.png) center center no-repeat;
        background-size: 100%;
        // clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        // shape-outside: polygon(0 0, 100% 0, 100% 100%, -1% 100%);
        @include vw($height: 1320,
          $width: 1090,
          $margin-top: -350);

        @include respond-to(xs) {
          background: url(/images/feeling-4-m.jpg) center center no-repeat;
          clip-path: none;
          shape-outside: none;
          height: auto;
          width: 100%;
          margin-top: 0;
          padding-bottom: 110%;
        }
      }

      &--knee {
        background: url(/images/feeling-2.png) center center no-repeat;
        background-size: 100%;
        // clip-path: polygon(0 -100%, 100% 0, 100% 100%, 40% 100%);
        // shape-outside: polygon(0 -100%, 100% 0, 100% 100%, 40% 100%);
        // height: 1320px;
        // width: 1100px;
        // margin-top: -370px;
        @include vw($height: 1320,
          $width: 1100,
          $margin-top: -370);

        @include respond-to(xs) {
          background: url(/images/feeling-2-m.jpg) center center no-repeat;
          clip-path: none;
          shape-outside: none;
          height: auto;
          width: 100%;
          margin-top: 0;
          padding-bottom: 110%;
        }
      }

      &--neck {
        background: url(/images/feeling-3.png) center center no-repeat;
        background-size: 100%;
        // clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        // shape-outside: polygon(0 0, 100% 0, 100% 100%, -1% 100%);
        // height: 1320px;
        // width: 880px;
        // margin-top: -500px;
        @include vw($height: 1320,
          $width: 880,
          $margin-top: -500,
        );

        @include respond-to(xs) {
          background: url(/images/feeling-3-m.jpg) center center no-repeat;
          clip-path: none;
          shape-outside: none;
          height: auto;
          width: 100%;
          margin-top: 0;
          padding-bottom: 110%;
        }
      }

      &--moving {
        background: url(/images/feeling-5.png) center center no-repeat;
        background-size: 100%;
        // clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        // shape-outside: polygon(0 0, 100% 0, 100% 100%, -1% 100%);
        // height: 1320px;
        // width: 1090px;
        // margin-top: -350px;
        @include vw($height: 1320,
          $width: 1090,
          $margin-top: -350);

        @include respond-to(xs) {
          background: url(/images/feeling-5-m.jpg) center center no-repeat;
          clip-path: none;
          shape-outside: none;
          height: auto;
          width: 100%;
          margin-top: 0;
          padding-bottom: 110%;
        }
      }
    }

    &-link {
      @include vw($font-size: 26,
        $padding: 20 0);
      text-align: right;
      display: block;
      text-transform: uppercase;
      font-weight: 600;
    }
  }

  &__main-mobile {
    display: none;

    @include respond-to(xs) {
      display: block;
    }

    .feeling__img {
      background: none;
      position: relative;

      img {
        min-width: 100%;
        min-height: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: auto;
      }
    }

    .feeling__nav {
      height: auto;
      ;
    }

    .feeling__link {
      width: 100%;
      position: static;
      ;
      transform: translateY(0);
      font-size: 30px;
      padding: 10px 0 10px;
      cursor: pointer;

    }

    .feeling__link-title {
      font-size: 30px;
      padding: 10px 0;
      margin: 0;
    }

  }
}

// .feeling {
//   @include vw($margin-top: -570);
//   position: relative;
//   z-index: 3;
//   overflow: hidden;

//   @include respond-to(xs) {
//     margin-top: 80px;
//   }

//   &__title {
//     @include vw($font-size: 80);
//     font-weight: 700;
//     text-align: center;
//     position: relative;
//     z-index: 3;

//     @include respond-to(xs) {

//       font-size: 50px;
//     }

//     span {
//       font-weight: 900;
//       color: $brand;
//     }
//   }

//   &__subtitle {
//     @include vw($font-size: 37);
//     color: #A3A2A2;
//     text-align: center;
//     font-weight: 400;
//     position: relative;
//     z-index: 3;

//     @include respond-to(xs) {
//       font-size: 21px;
//     }
//   }

//   &__nav {
//     @include vw($margin: 270 auto 0);
//     position: relative;
//     z-index: 2;
//     @include vw($width: 1200,
//       $padding: 0 15);
//     max-width: 100%;

//     @include respond-to(xs) {
//       margin-top: 10px;
//       width: 100%;
//       padding: 0px 40px;
//     }
//   }

//   &__link {
//     @include vw($font-size: 50,
//       $padding: 22 0,
//     );
//     font-weight: 700;
//     color: #000;
//     display: block;
//     line-height: 1.4;
//     position: relative;

//     &:after {
//       content: '';
//       @include vw($width: 408);
//       height: 1px;
//       bottom: 0;
//       position: absolute;
//       display: block;
//       background: #000;

//       @include respond-to(xs) {
//         width: 100%;
//       }
//     }

//     @include respond-to(xs) {
//       font-size: 30px;
//       padding: 22px 0px;
//     }
//   }

//   &__img {
//     float: right;
//     opacity: 0.4;
//     float: right;
//     background: url('/images/feeling-1-1.png') center center no-repeat;
//     background-size: 100%;
//     clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
//     shape-outside: polygon(0 0, 100% 0, 100% 100%, -1% 100%);
//     // height: 1320px;
//     // width: 1090px;
//     // margin-top: -350px;
//     @include vw($height: 1320,
//       $width: 1090,
//       $margin-top: -350);

//     @include respond-to(xs) {
//       height: auto;
//       width: 100%;
//       margin-top: 0;
//       clip-path: none;
//       shape-outside: none;
//       // padding-bottom: 110%;
//       height: 400px;
//       float: none;
//       position: static;
//       background: url('/images/feeling-1-m.jpg') center center no-repeat;
//     }
//   }

//   &__tab {
//     // display: flex;
//     position: relative;
//     display: none;
//     opacity: 0;
//     visibility: hidden;
//     ;

//     &.is-visible {
//       display: block;
//       opacity: 1;
//       visibility: visible;
//     }

//     &-title {
//       @include vw($margin-top: 270);
//       @include vw($font-size: 50,
//         $padding: 22 0,
//       );
//       font-weight: 700;
//       color: $brand;
//       display: block;
//       line-height: 1.4;
//       position: relative;

//       @include respond-to(xs) {
//         margin-top: 30px;
//       }

//       &:after {
//         content: '';
//         @include vw($width: 408);
//         height: 1px;
//         bottom: 0;
//         position: absolute;
//         display: block;
//         background: $brand;
//       }

//       @include respond-to(xs) {
//         font-size: 30px;
//       }
//     }

//     &-left {
//       // width: 40%;
//       @include vw($width: 1200,
//         $margin: 0 auto,
//         $padding: 0 15);
//       max-width: 100%;

//     }

//     &-content {

//       p {
//         @include vw($font-size: 30,
//           $margin-bottom: 30);
//         font-weight: 400;
//       }
//     }

//     .feeling__img {
//       opacity: 1;
//       background-size: 100% !important;

//       &--joint {}

//       &--back {
//         background: url(/images/feeling-4.png) center center no-repeat;
//         background-size: 100%;
//         clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
//         shape-outside: polygon(0 0, 100% 0, 100% 100%, -1% 100%);
//         @include vw($height: 1320,
//           $width: 1090,
//           $margin-top: -350);

//         @include respond-to(xs) {
//           background: url(/images/feeling-4-m.jpg) center center no-repeat;
//           clip-path: none;
//           shape-outside: none;
//           height: auto;
//           width: 100%;
//           margin-top: 0;
//           padding-bottom: 110%;
//         }
//       }

//       &--knee {
//         background: url(/images/feeling-2.png) center center no-repeat;
//         background-size: 100%;
//         clip-path: polygon(0 -100%, 100% 0, 100% 100%, 40% 100%);
//         shape-outside: polygon(0 -100%, 100% 0, 100% 100%, 40% 100%);
//         // height: 1320px;
//         // width: 1100px;
//         // margin-top: -370px;
//         @include vw($height: 1320,
//           $width: 1100,
//           $margin-top: -370);

//         @include respond-to(xs) {
//           background: url(/images/feeling-2-m.jpg) center center no-repeat;
//           clip-path: none;
//           shape-outside: none;
//           height: auto;
//           width: 100%;
//           margin-top: 0;
//           padding-bottom: 110%;
//         }
//       }

//       &--neck {
//         background: url(/images/feeling-3.png) center center no-repeat;

//         clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
//         shape-outside: polygon(0 0, 100% 0, 100% 100%, -1% 100%);
//         // height: 1320px;
//         // width: 880px;
//         // margin-top: -500px;
//         @include vw($height: 1320,
//           $width: 880,
//           $margin-top: -500,
//         );

//         @include respond-to(xs) {
//           background: url(/images/feeling-3-m.jpg) center center no-repeat;
//           clip-path: none;
//           shape-outside: none;
//           height: auto;
//           width: 100%;
//           margin-top: 0;
//           padding-bottom: 110%;
//         }
//       }

//       &--moving {
//         background: url(/images/feeling-5.png) center center no-repeat;
//         background-size: 100%;
//         clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
//         shape-outside: polygon(0 0, 100% 0, 100% 100%, -1% 100%);
//         // height: 1320px;
//         // width: 1090px;
//         // margin-top: -350px;
//         @include vw($height: 1320,
//           $width: 1090,
//           $margin-top: -350);

//         @include respond-to(xs) {
//           background: url(/images/feeling-5-m.jpg) center center no-repeat;
//           clip-path: none;
//           shape-outside: none;
//           height: auto;
//           width: 100%;
//           margin-top: 0;
//           padding-bottom: 110%;
//         }
//       }
//     }

//     &-link {
//       @include vw($font-size: 30);
//       font-weight: bold;

//     }
//   }

// }

.inside {
  @include vw($margin-top: -120);

  @include respond-to(xs) {
    margin-top: 50px;

  }


  .site-width {
    @include vw($padding-top: 200);

    @include respond-to(xs) {
      padding-top: 0;
      padding-top: 0;
      padding-bottom: 232px;
    }
  }

  .divider {
    transform: translate(-50%, 0%);

    @include respond-to(xs) {
      display: none;
    }
  }

  &__bg {
    background: url(/images/reduce-bg.png) no-repeat;
    background-size: 112%;
    // background-size: 118%;
    // background-position: -520px -906px;
    /* background-size: 100%; */
    background-position: 150% 104%;
    @include vw($background-position: -510 60);

    @include respond-to(xs) {
      background-position: 76% center;
      background-size: 178%;
    }

  }

  &__title {
    @include vw($font-size: 119,
      $width: 718,
      $margin: 0 auto,
      $padding-top: 260);
    font-weight: 700;
    color: $grey;
    line-height: 1;
    text-align: left;

    @include respond-to(xs) {
      font-size: 50px;
      width: 100%;
      margin: 0px auto;
      padding-top: 49px;
      padding-left: 40px;
    }

    span {
      display: block;
      @include vw($font-size: 148);
      font-weight: 900;

      @include respond-to(xs) {
        font-size: 50px;
      }
    }
  }

  &__subtitle {
    // @include vw($font-size: 50, $width: 718,
    //   $margin: -104 auto 110);
    @include vw($font-size: 50, $width: 718,
      $margin: 0 auto 110);
    font-weight: 700;
    color: #000;

    @include respond-to(xs) {
      font-size: 24px;
      padding-left: 40px;
      width: 100%;
      margin: 0px auto 50px;
    }
  }

  &__btns {
    @include vw($width: 868,
      $margin: 0 auto,
      $padding-bottom: 870);
    max-width: 100%;

    @include respond-to(xs) {
      padding-bottom: 0;
    }
  }

  &__btn {
    @include vw($font-size: 37,
      $width: 868,
      $height: 138,
      $margin-bottom: 50);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 700;
    border-radius: 5px;
    box-shadow: 0 0px 20px 2px rgba(0, 0, 0, 0.3);
    transition: all 0.4s ease;

    @include respond-to(xs) {
      max-width: 100%;
      font-size: 16px;
      min-height: 49px;
      height: auto;
      margin-bottom: 15px;
    }

    &:nth-child(even) {
      background: #fff url(/images/inside-btn.png) center center no-repeat;
      background-size: cover;
      color: #000;
    }

    &:nth-child(odd) {
      background: $brand url(/images/inside-btn.png) center center no-repeat;
      background-size: cover;
      color: #fff;
    }

    &:hover {
      box-shadow: 0 0 41px 2px rgba(0, 0, 0, .3);
      box-shadow: 0 0 5px 0px rgba(0, 0, 0, .3);
      transform: translateY(-3%);
    }
  }

}

.order {
  @include vw($margin-top: -600,
    $margin-bottom: 60);

  @include respond-to(xs) {
    margin-top: 0;
  }

  &__title {
    @include vw($font-size: 80);
    font-weight: 700;
    text-align: center;

    @include respond-to(xs) {
      font-size: 50px;

    }
  }

  &__subtitle {
    @include vw($font-size: 37,
      $margin-bottom: 78);
    font-weight: 400;

    @include respond-to(xs) {
      font-size: 16px;
      ;

    }

    text-align: center;
  }

  &__cards {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
    @include vw($max-width: 880);

    @include respond-to(xs) {
      flex-wrap: wrap;
    }

    &.special-offer {
      .offer__timer {
        position: absolute;
        top: 0;
        width: 90%;
        background: $secondary;
        left: 5%;
        transform: translateY(-100%);
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        text-align: center;
        padding: 3px 11px;
        font-weight: 700;
        font-size: 13px;
        color: #fff;
        display: flex;
      }

      .order__card-stack {
        .offer__timer {
          background: #fff;
          color: $secondary;
        }

        .order__card-inner {
          &:after {

            background: $secondary;
          }
        }
      }

      .order__price {
        display: none;
      }

      .order__price-special {
        display: block;

        p {
          color: #999;
          display: block;
          text-align: center;

          span {

            position: relative;

            &:after {
              content: '';
              width: 120%;
              position: absolute;
              @include vw($height: 3);
              display: block;
              background: $secondary;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }

      .order__btn {
        background: $secondary;

        &:hover {
          color: #fff;
          opacity: 0.9;
        }
      }
    }
  }

  .offer__timer {
    display: none;
  }

  .order__price-special {
    display: none;
  }

  &__btn {
    z-index: 2;
    position: relative;
    background: $brand;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    @include vw($width: 172,
      $height: 55,
      $font-size: 23,
      $margin: 0 auto 30);
    border-radius: 5px;
    box-shadow: 0 0px 11px 2px rgba(0, 0, 0, 0.16);

    @include respond-to(xs) {
      font-size: 17px;
      width: 160px;
      height: 45px;

    }
  }

  &__card {
    box-shadow: 0 0px 11px 2px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    flex-basis: 47%;

    flex-shrink: 0;
    position: relative;

    @include respond-to(xs) {
      flex-basis: 100%;
      margin-bottom: 40px;
      max-width: 295px;
      margin-left: auto;
      margin-right: auto;
    }

    &-inner {
      @include vw($padding: 30);
      position: relative;
    }

    &-bottle {
      background: #fff;

      @include respond-to(xs) {
        order: 1;
      }
    }

    &-stack {
      position: relative;

      @include respond-to(xs) {
        order: 0;
      }

      .order__card-inner {
        overflow: hidden;

        &:after {
          content: '';
          position: absolute;
          width: 200%;
          height: 200%;
          // left: -160%;
          background: $brand;
          // top: -104%;
          transform: translate(-100%, -106%) rotate(-24deg);
          transform-origin: top left;
          z-index: 0;

          @include respond-to(xs) {
            left: -259%;
            background: #3B5D26;
            top: -91%;
            transform: rotate(-34deg);
          }
        }

      }
    }
  }

  &__price {
    @include vw($font-size: 50,
      $margin-bottom: 18);
    text-align: center;
    font-weight: 700;

    @include respond-to(xs) {
      font-size: 30px;
      ;
    }

    p {
      @include vw($font-size: 20);
      color: $brand;
      display: block;
      text-align: center;

      @include respond-to(xs) {
        font-size: 16px;
      }
    }
  }

  &__bottle {
    background: #fff;

    &-title {
      @include vw($font-size: 50);
      font-weight: 700;
      color: #000;
      text-align: center;
    }

    &-img {
      width: 95%;

      @include vw($margin: 0 auto 5);
    }

    &-subtitle {
      text-align: center;
      font-weight: 400;
      @include vw($font-size: 16,
        $margin-bottom: 25);

      span {
        @include vw($font-size: 21);
        font-weight: 500;
        display: block;
      }
    }
  }

  &__stack {
    position: relative;

    &-title {
      position: relative;
      z-index: 2;
      @include vw($font-size: 50);
      font-weight: 700;
      color: #fff;
      text-align: center;
      line-height: 1;

      @include respond-to(xs) {
        font-size: 30px;
      }
    }

    &-subtitle {
      position: relative;
      z-index: 2;
      text-align: center;
      font-weight: 400;
      @include vw($font-size: 16,
        $margin-bottom: 18);
      color: #fff;

      @include respond-to(xs) {
        font-size: 16px;
        margin-bottom: 24px;
      }

      span {
        @include vw($font-size: 21);
        font-weight: 500;
        display: block;

        @include respond-to(xs) {
          font-size: 12px;
        }
      }
    }

    &-img {
      position: relative;
      z-index: 2;
      width: 82%;
      @include vw($margin: 0 auto 40);
    }
  }
}

.reviews {
  @include vw($margin-bottom: 285);

  @include respond-to(xs) {
    margin-bottom: 195px;
  }

  .js-review-hidden {
    display: none;
  }

  &__title {
    @include vw($font-size: 50);
    font-weight: 700;
    text-align: center;

    @include respond-to(xs) {
      font-size: 50px;
      margin-bottom: 19px;
    }
  }

  &__subtitle {
    @include vw($font-size: 37,
      $margin-bottom: 30);
    font-weight: 300;
    text-align: center;

    @include respond-to(xs) {
      font-size: 16px;
    }
  }

  &__descr {
    @include vw($font-size: 21,
      $max-width: 770,
      $margin: 0 auto 50);
    font-weight: 300;
    text-align: center;
    width: 100%;

    @include respond-to(xs) {
      font-size: 16px;
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    @include vw($width: 840,
      $margin: 0 auto 30);

    @include respond-to(xs) {
      flex-wrap: wrap;
      width: 100%;
    }

  }

  &__rating {
    flex-grow: 0;
    flex-shrink: 0;
    @include vw($padding: 0 32 0 0);
    flex-basis: 52%;

    @include respond-to(xs) {
      flex-basis: 100%;
      padding: 0;
      margin-bottom: 35px;

    }

    &-row {
      display: flex;
      justify-content: space-between;
      @include vw($margin-bottom: 23);
      cursor: pointer;

      @include respond-to(xs) {
        margin-bottom: 11px;

      }

      &:hover {
        .reviews__rating-col {
          color: rgb(199, 53, 58);
        }
      }
    }

    &-stars {
      text-align: center;

      @include respond-to(xs) {
        padding-right: 15px;
      }

      img {
        width: 100%;
      }
    }

    &-col {
      flex-basis: 57%;
      text-align: center;
      @include vw($font-size: 21);
      font-weight: 300;
      flex-shrink: 0;

      @include respond-to(xs) {
        font-size: 21px;
        flex-basis: 30%;
        font-size: 21px;
        text-align: left;
      }

    }

  }

  &__action {
    @include respond-to(xs) {
      flex-basis: 100%;
    }

    p {
      @include vw($font-size: 21,
        $margin-bottom: 43);
      font-weight: 300;
      text-align: center;
      line-height: 1.5;

      @include respond-to(xs) {
        display: none;
      }
    }

    .btn {
      @include vw($font-size: 23,
        $padding: 0,
        $height: 77);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      font-weight: 300;
      text-align: center;

      @include respond-to(xs) {
        font-size: 15px;
        height: 56px;
      }
    }
  }

  &__btn {}

  &__inner {
    @include vw($margin-bottom: 90);

    @include respond-to(xs) {
      margin-top: 159px;
    }
  }

  &__item {
    position: relative;
    @include vw($margin-left: 100,
      $margin-bottom: 40,
      $padding: 45 100);

    @include respond-to(xs) {
      margin-left: 0;
      padding-top: 47px;
      padding: 47px 15px 15px 15px;
      margin-bottom: 80px;

    }

    &:nth-child(even) {
      // background: url('/images/review-bg-2.png') center center no-repeat;
      // background-size: cover;
      // margin-top: -24px;
      // padding: 135px 100px 20px 100px;
      // min-height: 585px;
      box-shadow: 1px 0 11px 1px rgba(0, 0, 0, 0.16);
      @include vw($padding: 45 100);
      // @include vw($margin-top: -24,
      //   $padding: 135 100 20 100,
      //   $min-height: 585);

      .reviews__item-img {
        @include vw($top: 160);

        @include respond-to(xs) {
          top: -33px;
          left: 11%;
        }

      }

      @include respond-to(xs) {
        background: none;
        border: 1px solid #eee;
        box-shadow: 1px 0 11px 1px rgba(0, 0, 0, 0.16);
        min-height: 0;
        margin-left: 0;
        padding-top: 47px;
        padding: 47px 15px 15px 15px;
        margin-bottom: 80px;
      }
    }

    &:nth-child(odd) {
      // background: url('/images/review-bg-1.png') center center no-repeat;
      // background-size: cover;
      box-shadow: 1px 0 11px 1px rgba(0, 0, 0, 0.16);

      @include respond-to(xs) {
        background: none;
        border: 1px solid #eee;
        box-shadow: 1px 0 11px 1px rgba(0, 0, 0, 0.16);
        min-height: 0;
        margin-left: 0;
        padding-top: 47px;
        padding: 47px 15px 15px 15px;
        margin-bottom: 80px;
      }

    }

    &-img {
      @include vw($width: 175,
        $height: 175,
        $top: 70);
      left: 0;
      transform: translateX(-50%);
      position: absolute;

      @include respond-to(xs) {
        width: 81px;
        height: 81px;
        top: -33px;
        left: 11%;
      }

    }

    &-stars {
      @include vw($margin-bottom: 46,
        $width: 175);

      @include respond-to(xs) {
        margin-bottom: 10px;
        width: 135px;
        margin-left: 30px;
      }
    }

    &-rating {}

    &-text {
      width: 92%;
      margin: auto;
      @include vw($font-size: 21,
        $margin-bottom: 60);
      font-weight: 400;
      line-height: 1.4;

      @include respond-to(xs) {
        font-size: 14px;
        margin-bottom: 20px;
      }

      p {

        line-height: 1.4;
      }
    }

    &-author {
      color: $brand;
      margin: auto;
      @include vw($font-size: 21);
      font-weight: 300;
      text-align: center;
    }
  }

  &__btn-more {
    @include vw($font-size: 16,
      $margin: 0 auto,
      $border: 5 solid #000,
      $border-radius: 10,
      $width: 351,
      $height: 54);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-weight: 400;
    text-align: center;

    @include respond-to(xs) {
      height: 50px;
      max-width: 230px;
      width: 100%;
      font-size: 16px;
    }

    a {
      color: #000;
      font-weight: 400;

      @include respond-to(xs) {
        font-size: 16px;
      }
    }

  }
}

.footer {
  background: url(/images/footer-bg.jpg) center center no-repeat;
  background-size: cover;
  @include vw($padding: 140 0 180);
  position: relative;

  @include respond-to(xs) {
    padding: 66px 0;
    ;

  }

  .wave {
    top: 0;
    transform: translateY(-50%);

    @include respond-to(xs) {
      top: 0;
      transform: translateY(-50%);
      width: 145px;
    }
  }

  &__title {
    @include vw($font-size: 50,
      $margin-bottom: 60);
    font-weight: 700;
    color: #fff;
    text-align: center;

    @include respond-to(xs) {
      font-size: 30px;
      margin-bottom: 24px;

    }
  }

  &__form {
    @include vw($max-width: 830,
      $margin: 0 auto);
    display: flex;
    flex-wrap: wrap;

    input {
      @include vw($font-size: 21,
        $height: 85,
        $padding: 5 30);
      width: 80%;
      font-weight: 400;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      flex-shrink: 1;

      @include respond-to(xs) {
        height: 40px;
        font-size: 9px;
        padding: 5px 10px;
        border-radius: 8px;
        flex-basis: 100%;
        order: 0;

      }
    }

    button {
      color: #fff;
      background: $brand;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      @include vw($font-size: 23,
        $width: 263,
        $border-radius: 40,
        $margin-left: -100);
      font-weight: 400;
      border: none;
      flex-shrink: 0;

      @include respond-to(xs) {
        order: 3;
        width: 136px;
        height: 40px;
        margin: 20px auto 0;
        font-size: 16px;
      }
    }

    &-descr {
      text-align: center;
      flex-basis: 100%;
      color: #fff;
      @include vw($margin: 50 0 0,
        $font-size: 21);
      font-weight: 400;

      @include respond-to(xs) {
        font-size: 9px;
        order: 1;
        flex-basis: 100%;
        margin-top: 0;
      }
    }
  }
}

.popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  overflow: auto;
  // display: none; 
  transition: all 0.4s ease;
  // transform: translateY(-10%);
  opacity: 0;
  visibility: hidden;
  height: 100vh;
  z-index: -1;

  &.is-visible {
    display: flex;
    transform: translateY(0%);
    opacity: 1;
    visibility: visible;
    z-index: 99999;
  }

  &__inner {
    background: #fff;
    @include vw($width: 1174,
      $border-radius: 24,
      $padding: 60 0);
    box-shadow: 0 0px 11px 2px rgba(0, 0, 0, 0.16);
    max-width: 98%;
    margin: auto;
    // max-height: 70vh; 
    position: relative;

  }

  &__close {
    position: absolute;
    @include vw($top: 20,
      $right: 20,
      $width: 38, $height: 38,
    );
    border-radius: 50%;
    background: $brand;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;

    box-shadow: 0 0px 11px 2px rgba(0, 0, 0, 0.16);

    @include respond-to(xs) {
      position: sticky;
      margin-left: auto;
      // margin-right: -30px;
      margin-top: -20px;
    }

    img {
      @include vw($width: 16);
    }
  }

  &__title {
    @include vw($font-size: 37,
      $margin-bottom: 15,
      $padding: 0 80);
    text-align: center;
    font-weight: 700;
    color: #000;
  }

  &__content {
    @include vw($font-size: 26,
      $padding: 0 80);
    font-weight: 400;
    @include vw($margin-bottom: 30);

    p {
      @include vw($margin-bottom: 15);
    }

    &--center {
      text-align: center;
    }
  }

  &__links {
    display: flex;
    justify-content: space-around;

    a {
      @include vw($font-size: 21,
        $padding: 10 20);
      font-weight: 700;
    }
  }

  ul {
    list-style: disc;
    @include vw($margin: 30 0 30);

    li {
      @include vw($padding-bottom: 30);
      font-weight: bold;

      span {
        color: $secondary;
      }
    }
  }

  .btn {
    @include vw($font-size: 40);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);

    @include respond-to(xs) {
      width: 270px;
      text-align: center;
    }
  }
}

.form {
  @include vw($margin-top: 90);

  &__row {
    display: flex;
    justify-content: space-around;

    @include respond-to(xs) {
      flex-wrap: wrap;
    }

    input {
      @include vw($height: 109,
        $padding: 7 30,
        $font-size: 30,
        $border-radius: 38,
        $margin-bottom: 40);
      font-weight: 300;
      border: none;
      flex-grow: 1;
      box-shadow: 0 0px 11px 2px rgba(0, 0, 0, 0.16);

      &:first-child {
        flex-basis: 38%;
        margin-right: 2%;

        @include respond-to(xs) {
          flex-basis: 100%;
          margin-right: 0;
          margin-bottom: 30px;
        }
      }
    }
  }
}

.mobile-hide {
  @include respond-to(xs) {
    display: none;
  }
}

.popup-contact {
  position: fixed;
  z-index: 99;
  @include vw($right: 20,
    $bottom: 140);
  //  background: url('/images/inside-btn.png') center no-repeat;
  background: none;
  z-index: 999;
  transform: translateX(200%);
  opacity: 0;
  transition: all 0.4s ease-out;

  @include respond-to(xs) {
    right: 0%;

  }

  &.is-visible {
    transform: translateX(0%);
    opacity: 1;
  }

  .popup__close {
    @include respond-to(small) {

      top: 14px;
      right: 34px;
    }
  }

  .popup__inner {
    background: #ececec url('/images/inside-btn.png');
    // background: rgba(255, 255, 255, 0.6) url('/images/inside-btn.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 0% 12%;

    @include vw($width: 700);
    max-width: 90%;

    @include respond-to(xs) {
      width: 90%;
      margin: auto;
      background: rgba(255, 255, 255, 1) url('/images/inside-btn.png');
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: 0% 12%;

    }
  }

  .form__row {
    flex-wrap: wrap;
    @include vw($margin-bottom: 50);

    input {
      background: none;
      box-shadow: none;
      border-bottom: 1px solid #999;
      @include vw($border-bottom: 1 solid #333,
        $border-radius: 0,
        $padding: 10 20,
        $height: auto,
        $margin: 0 auto 70,
        $font-size: 26);
      text-align: center;
      width: 80%;
      flex-grow: 0;
      flex-basis: 80%;
    }
  }

  .btn {
    @include vw($border: none,
      $padding: 20 80);
  }
}

.popup-feeling {
  .popup__inner {
    padding: 42px 0px;
  }

  .popup__close {
    z-index: 9;
  }

  .feeling__img {
    opacity: 1;
    background: none;
    position: relative;

    img {
      min-width: 100%;
      min-height: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: auto;
      z-index: 0;
    }
  }

  .feeling__tab-title {
    margin-top: 0px;
    font-size: 30px;
    margin-bottom: 10px;
    padding: 15px 20px;
  }

  // &[data-popup="joint"]{
  //   background: url(/images/feeling-1-m.jpg) center center no-repeat;
  // }
  .feeling__tab-content {
    height: auto;
    padding: 0 20px;
  }
}