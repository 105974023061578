//   //Базовая ширина сайта. При данном размере сайт будет смотреться 1 в 1 как в psd макете при vw размерах
//   $siteWidth: 1920;
 
//   //Точки изменения размеров
//   $breakpoints: (
// 	// '1500': 0.75,
// 	'1500': 0.7,
// 	'1200': 0.7,
// 	'992': 0.65,
// 	'768': 0.4,
// 	'576': 0.37,
// 	// '400': 0.5,
//   ); 
  
//   @function get-vw($px, $multi:0.5) {
// 	  @return $px*$multi;
//   }
 
//   @function generateVw($values, $multi:1) {
// 	  $vw: ();
// 	  $px: ();
// 	  @each $value in $values {
// 		 @if type-of($value) != 'number' {
// 			  $px: append($px , $value);
// 			  $vw: append($vw, $value);
// 		  }
// 		  @else {
// 			  $val: parseInt($value);
// 			  $px: unquote('#{append($px,  $val*$multi)}px');
// 			  $vw: unquote('#{append($vw,  get-vw($val, $multi))}px');
// 		  }
// 	  }
// 	  @return ('vw':$vw, 'px':$px);
//   }
  
//   // Приведение типов к int
//   @function parseInt($n) {
// 	  @return $n / ($n * 0 + 1);
//   }


 //Базовая ширина сайта. При данном размере сайт будет смотреться 1 в 1 как в psd макете при vw размерах
 $siteWidth: 1920;
 
 //Точки изменения размеров
 $breakpoints: (
	 '1500': 0.9,
 	'1200': 1,
 	'700': 1.3,
 ); 
 // Получаем размер элемента в vw
 // $px - пиксели
 // $multi - Множитель, берется из $breakpoins
 @function get-vw($px, $multi:1) {
 	@return $px/$siteWidth*100*$multi;
 }
 
 // Генерируем массив с размерами исходя из $breakpoins
 // $values параметр или параметры элемента (ex margin: 10 auto 20)
 // $multi - Множитель, берется из $breakpoins
 // @return - Возращаемый размер, тип
 @function generateVw($values, $multi:1) {
 	$vw: ();
 	$px: ();
 	@each $value in $values {
		@if type-of($value) != 'number' {
 			$px: append($px , $value);
 			$vw: append($vw, $value);
 		}
 		@else {
 			$val: parseInt($value);
 			$px: unquote('#{append($px,  $val*$multi)}px');
 			$vw: unquote('#{append($vw,  get-vw($val, $multi))}vw');
 		}
 	}
 	@return ('vw':$vw, 'px':$px);
 }
 
 // Приведение типов к int
 @function parseInt($n) {
 	@return $n / ($n * 0 + 1);
 }